.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding-top: 1rem;
}

.app__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.app__profile-item {
    width: 190px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem;

    img {
        width: 100%;
        height: 170px;
        border-radius: 15px;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px) {
        width: 370px;
        margin: 2rem 4rem;

        img {
            height: 320px;
        }
    }
}

.p-about-me {
    width: 75%;
    font-size: 20px;
    color: var(--text-color);
    padding-top: 3rem;
    @media screen and (max-width: 450px) {
        font-size: 18px;
        width: 85%;
    }
}

.moto-text {
    font-weight: 500;
    font-style: italic;
    color: var(--secondary-color);
    text-align: center;
}
