.app__skills {
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding-top: 1rem;
}

.app__skills-container {
    width: 90%;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media screen and (max-width:900px) {
        width: 100%;
        flex-direction: column;
    }
}

.app__skills-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-right: 5rem;
    flex-direction: column;
    max-width: 400px;
    @media screen and (max-width:900px) {
        margin-right: 0;
        justify-content: center;
        align-items: center;
    }
}

.app__skills-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.app__skills-item {
    flex-direction: column;
    text-align: center;
    margin: 1rem;
    transition: all 0.3s ease-in-out;

    div {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-color: #fff;

        img {
            width: 50%;
            height: 50%;
        }

        &:hover {
            box-shadow: 0 0 25px #e4e4e4 ;
        }

        @media screen and (min-width: 2000px) {
            width: 150px;
            height: 150px;
        }
        
        @media screen and (max-width: 900px) {
            width: 70px;
            height: 70px;
        }
    }

    p {
        font-weight: 500;
        margin-top: 0.5rem;
    }

    @media screen and (min-width: 2000px) {
        margin: 1rem 2rem;

        p {
            margin-top: 1rem;
        }
    }
}