@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

:root {
  --font-base: "DM Sans", sans-serif;
  --bg-color: #D7DCD5;
  --secondary-color: #261710;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --text-color: #8D7E6B;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}